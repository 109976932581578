import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 50px;
  background: #ffdd57;
  text-align: center;
`;

const Heading = styled.h1`
  color: #d7263d;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const Form = styled.form`
  margin-top: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  background: #d7263d;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background: #c1272d;
  }
`;

function Contact() {
  return (
    <ContactContainer id="contact">
      <Heading>联系我们</Heading>
      <Paragraph>如果您有任何疑问，请随时联系我们。</Paragraph>
      <Form>
        <Label>
          名字:
          <Input type="text" name="name" />
        </Label>
        <Label>
          信息:
          <Textarea name="message"></Textarea>
        </Label>
        <Button type="submit">发送</Button>
      </Form>
    </ContactContainer>
  );
}

export default Contact;
