import React from 'react';
import styled from 'styled-components';

const ServicesContainer = styled.div`
  padding: 50px;
  background: #ffdd57;
  text-align: center;
`;

const Heading = styled.h1`
  color: #d7263d;
  margin-bottom: 20px;
`;

const ServiceList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
`;

const ServiceItem = styled.li`
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ServiceImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
`;

const ServiceInfo = styled.div`
  flex-grow: 1;
  text-align: left;
`;

const ServiceTitle = styled.h2`
  color: #d7263d;
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const ServiceDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
`;

function Services() {
  return (
    <ServicesContainer id="services">
      <Heading>我们的服务</Heading>
      <ServiceList>
        <ServiceItem>
          <ServiceImage src="/images/service1.jpg" alt="服务1" />
          <ServiceInfo>
            <ServiceTitle>服务1</ServiceTitle>
            <ServiceDescription>描述和详情</ServiceDescription>
          </ServiceInfo>
        </ServiceItem>
        <ServiceItem>
          <ServiceImage src="/images/service2.jpg" alt="服务2" />
          <ServiceInfo>
            <ServiceTitle>服务2</ServiceTitle>
            <ServiceDescription>描述和详情</ServiceDescription>
          </ServiceInfo>
        </ServiceItem>
        <ServiceItem>
          <ServiceImage src="/images/service3.jpg" alt="服务3" />
          <ServiceInfo>
            <ServiceTitle>服务3</ServiceTitle>
            <ServiceDescription>描述和详情</ServiceDescription>
          </ServiceInfo>
        </ServiceItem>
      </ServiceList>
    </ServicesContainer>
  );
}

export default Services;
