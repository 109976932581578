import React from 'react';
import styled from 'styled-components';

const HomeContainer = styled.div``;

const Header = styled.header`
  background-color: #d2a97a;
  padding: 100px 20px;
  text-align: center;
`;

const Heading = styled.h1`
  color: #000;
  font-size: 4rem;
  margin-bottom: 20px;
`;

const Subheading = styled.p`
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 40px;
`;

const Section = styled.section`
  padding: 50px 20px;
  text-align: center;
  background-color: #d2a97a;
`;

const SectionHeading = styled.h2`
  color: #000;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const SectionText = styled.p`
  color: #333;
  font-size: 0.9rem;
  margin-bottom: 40px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ImageCard = styled.div`
  margin: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 10px;
`;

function Home() {
  return (
    <HomeContainer>
      <Header>
        <Heading>艺苑</Heading>
        <Subheading>欢迎来到艺苑，您的设计作品一站式购物平台。</Subheading>
      </Header>
      <Section>
        <SectionHeading></SectionHeading>
        <ImageWrapper>
          <ImageCard>
            <Image src="/海豚1.jpg" alt="项目1" />
            <SectionText>本设计的仿生海豚水下航行器在外观上模仿真实海豚，采用流线型体态以减少水下阻力，提高速度与机动性。</SectionText>
          </ImageCard>
          <ImageCard>
            <Image src="/海苔机1.jpg" alt="项目2" />
            <SectionText>本产品融合了美味DIY功能，旨在让用户轻松享受制作海苔脆片的乐趣。</SectionText>
          </ImageCard>
          <ImageCard>
            <Image src="/熊猫灯1.jpg" alt="项目3" />
            <SectionText>这款夜灯的设计灵感源自对中华传统文化和环保理念的深刻理解与尊重。以熊猫为主题，象征着国宝形象和对生态环境的关爱;</SectionText>
          </ImageCard>
        </ImageWrapper>
      </Section>
    </HomeContainer>
  );
}

export default Home;
