import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.div`
  padding: 50px;
  background: #ffdd57;
  text-align: center;
`;

const Heading = styled.h1`
  color: #d7263d;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  color: #333;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
`;

function About() {
  return (
    <AboutContainer id="about">
      <Heading>关于我们</Heading>
      <Paragraph>
        艺术花园是一家致力于提供顶级设计解决方案的创意工作室。我们的团队由经验丰富的设计师组成，他们将艺术与技术相结合，打造独特而引人注目的作品。无论是平面设计、室内设计还是UI/UX设计，我们始终专注于客户满意度和创新。
      </Paragraph>
      <Paragraph>
        在艺术花园，我们相信每一个设计都有其独特的故事。我们的使命是帮助客户释放他们的创意潜力，通过我们精心制作的设计作品，让他们的品牌更加出众。加入我们，一起探索无限的可能性。
      </Paragraph>
    </AboutContainer>
  );
}

export default About;
