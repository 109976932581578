import React from 'react';
import styled from 'styled-components';

const ProductsContainer = styled.div`
  padding: 50px;
  background: #ffdd57;
  text-align: center;
`;

const Heading = styled.h1`
  color: #d7263d;
  margin-bottom: 20px;
`;

const ProductList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
`;

const ProductItem = styled.li`
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
`;

const ProductInfo = styled.div`
  flex-grow: 1;
  text-align: left;
`;

const ProductTitle = styled.h2`
  color: #d7263d;
  font-size: 1.2rem;
  margin-bottom: 5px;
`;

const ProductPrice = styled.p`
  color: #333;
  font-size: 1rem;
`;

const ProductDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
`;

function Products() {
  return (
    <ProductsContainer id="products">
      <Heading>我们的产品</Heading>
      <ProductList>
        <ProductItem>
          <ProductImage src="/images/product1.jpg" alt="产品1" />
          <ProductInfo>
            <ProductTitle>释放你的想象力</ProductTitle>
            <ProductPrice>¥25</ProductPrice>
            <ProductDescription>描述和详情</ProductDescription>
          </ProductInfo>
        </ProductItem>
        <ProductItem>
          <ProductImage src="/images/product2.jpg" alt="产品2" />
          <ProductInfo>
            <ProductTitle>设计奇迹等你来发现</ProductTitle>
            <ProductPrice>¥25</ProductPrice>
            <ProductDescription>描述和详情</ProductDescription>
          </ProductInfo>
        </ProductItem>
        <ProductItem>
          <ProductImage src="/images/product3.jpg" alt="产品3" />
          <ProductInfo>
            <ProductTitle>创新和启发</ProductTitle>
            <ProductPrice>¥25</ProductPrice>
            <ProductDescription>描述和详情</ProductDescription>
          </ProductInfo>
        </ProductItem>
      </ProductList>
    </ProductsContainer>
  );
}

export default Products;
